

.fira-sans-extra-condensed-thin {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.fira-sans-extra-condensed-extralight {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.fira-sans-extra-condensed-light {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.fira-sans-extra-condensed-regular {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fira-sans-extra-condensed-medium {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.fira-sans-extra-condensed-semibold {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.fira-sans-extra-condensed-bold {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.fira-sans-extra-condensed-extrabold {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.fira-sans-extra-condensed-black {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.fira-sans-extra-condensed-thin-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.fira-sans-extra-condensed-extralight-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.fira-sans-extra-condensed-light-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.fira-sans-extra-condensed-regular-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.fira-sans-extra-condensed-medium-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.fira-sans-extra-condensed-semibold-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.fira-sans-extra-condensed-bold-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.fira-sans-extra-condensed-extrabold-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.fira-sans-extra-condensed-black-italic {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 900;
  font-style: italic;
}


body {
  font-family: "Fira Sans Extra Condensed", sans-serif;

}

.continer{
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.user-conteiner {
  display: flex;
  align-items: center;
  /* background-color: aqua; */
  padding: 10px;
}
.user-avatar{
  display: flex;
}
.user-avatar img{
  height: 40px;
  border-radius: 100px;
  margin-right: 10px;
}

.conteiner-username{
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
}
.user-hello{
  font-weight: 400;
    font-size: 14px;
    color: #777777;
}

.balans-conteiner{
  display: flex;

  align-items: center;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 5px 5px 5px;
  background-color: #febf32;
  /* width: 50%; */
  position: relative;
}

.width-50 {
  width: 50vw;
}

.width-100 {
  width: 100vw;
}
.balans{

  font-size: 50px;
  display: flex;
  align-items: center;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  width: 160px;
}
.balans-conteiner-other{
  display: flex;

  align-items: center;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 5px 5px 5px;
  background-color: #99c886;
  width: 50%;
  position: relative;
}
.balans-other{

  font-size: 50px;
  display: flex;
  align-items: center;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  width: 160px;
}


.icon-balans{
  position: absolute;
  top: 15px;
  right: 15px;

}
.namesystem-conteiner{
  font-weight: 600;
  padding: 5px 5px 5px 5px;
  background-color: #fbb316;
  color: white;
  border-radius: 100px;
  
}
.namesystem-conteiner-other{
  font-weight: 600;
  padding: 5px 5px 5px 5px;
  background-color: #94ba74;
  color: white;
  border-radius: 100px;
  
}
.namesystem{
  padding: 0 5px 0 5px;
  font-size: 12px;
}
.conteiner-other-bonus{
  display: flex;
  overflow-x: auto;
  color: black;

}

.conteiner-other{
  overflow-x: auto;

}


.conteiner-qr{
  display: flex;

  padding: 12px;

  background: rgb(255, 255, 255);
  border-radius: 30px;

}

.barcode{
  display: flex;
  flex-direction: column;
  align-items: center;

  
}
.image-gallery {
  margin: 10px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  border-radius: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} 

.modal-content {
  background-color: white;
  padding: 35px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.modal-text {
  margin-bottom: 20px;
}

.name-qr{
  font-size: 18px;
    font-weight: 700;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.modal-content button {
  background-color: #fbb316;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
}

.modal-content button:hover {
  background-color: #0056b3; /* Цвет фона кнопки при наведении */
}