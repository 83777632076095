.box {
  /* padding: 5px; */

    max-width: 100%;
    overflow-x: hidden;
}

.box .qr {
  background-image: url(../assets/barcode.svg);
  background-size: 100% 100%;
  height: 100px;
  width: 378px;
  position: absolute;
}

.box .group {
  display: flex;
  align-items: center;
}

.box .overlap-group {
  background-color: #a8d493;
  border: 2px solid;
  border-color: #f3eee0;
  border-radius: 10px;
  height: 37px;
  position: absolute;
  width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 5px;
}

.box .text-wrapper {
  color: #ffffff;
  font-family: "Fira Sans Extra Condensed", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}
